div.emphasis {
  border: 1px solid #d1d5da;
  border-radius: 6px;
  padding: 5px;
  margin-bottom: 10px;
}

.flex-center {
  display: flex;
  justify-content: center;
}

figure {
  display: inline-block;
}

figcaption {
  font-style: italic;
}

.section {
  margin-bottom: 15px;
}

a.header {
  color: inherit;
}

a.header:hover {
  text-decoration: none;
}

table.changelog {
  font-size: 14px;
  text-align: left;
  border: 1px solid;
  margin-bottom: 2em;
}

.nowrap {
  white-space: nowrap;
}

.grid-wrapper {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 10px;
  color: #444;
}

.grid-box {
  background-color: #424242;
  color: #fff;
  border-radius: 5px;
  padding: 5px;
  font-size: 150%;

  figcaption {
    padding-top: 5px;
    display: block;
  }
}

iframe {
  width: 100%;
}

@media (max-width: 600px) {
  .grid-wrapper { grid-template-columns: 100%; }
}

.icon {
  @extend .svg-icon
}

.social-media-list {
  img {
    width: 1.25em;
    height: 1.25em;
  }
}
